.silentSea {
    --fsTit: 80px;
    --fsDetail: 30px;
    --fsTitOverview: 49px;
    --fsDetailOverView: 18px;
    --maxHeight: 1163px;
    --widthOverView: 1500px;
    --paddingOverview: 150px 25px;
    --pbContentOverview: 115px;
    --heightGif: 1080px;
    --paddingContent: 70px;
    --paddingSecondImg: 85px;
    color: #fff;
    background: #111;
    font-family: AppleGothic;

    .background {
        max-height: var(--maxHeight);
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            .title {
                font-size: var(--fsTit);
            }

            .detail {
                font-size: var(--fsDetail);
                line-height: 2;
            }
        }
    }

    .overview {
        &-container {
            padding: var(--paddingOverview);
            max-width: var(--widthOverView);
            margin: 0 auto;

            .overview-content {
                display: grid;
                grid-template-columns: 30% auto;
                padding-bottom: var(--pbContentOverview);

                .title {
                    font-size: var(--fsTitOverview);
                }

                .detail {
                    font-size: var(--fsDetailOverView);
                    line-height: 1.8;
                    white-space: pre-line;
                }

                .detailContent {
                    padding-top: var(--paddingContent);
                }
            }

            .centerContent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .overview-img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .overview-img-second {
                padding-top: var(--paddingSecondImg);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

    }

    .gif_end_02 {
        height: var(--heightGif);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .front {
            position: absolute;
            top: var(--heightGif/2);
            left: 50%;
            z-index: 30;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80%;
                height: 80%;
                object-fit: cover;
            }
        }

        .back {
            position: absolute;
            top: var(--heightGif/2);
            left: 0;

            img {

                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }



        @media screen and (max-width: 500px) {
            height: unset;
        }
    }

    .img-gif-bg {
        img {

            width: 100%;
        }
    }

    .layout-bg {
        position: relative;
        z-index: 33;

        &-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .img-gif-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        video {
            width: 33.3%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 1080px) {
        --fsTit: 60px;
        --fsDetail: 22px;
        --fsTitOverview: 35px;
        --fsDetailOverView: 18px;
        --maxHeight: 1163px;
        --widthOverView: 1500px;
        --paddingOverview: 100px 25px;
        --pbContentOverview: 85px;
        --heightGif: 1080px;
        --paddingContent: 40px;
        --paddingSecondImg: 45px;

        br {
            display: none;
        }

        .overview-container .overview-content .detail {

            white-space: normal;
        }
    }

    @media screen and (max-width: 767px) {
        --fsTit: 30px;
        --fsDetail: 18px;
        --fsTitOverview: 22px;
        --fsDetailOverView: 14px;
        --maxHeight: 763px;
        --paddingOverview: 60px 20px;
        --pbContentOverview: 45px;
        --heightGif: 60vh;
        --paddingContent: 30px;
        --paddingSecondImg: 35px;

        .img-gif-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .overview-container .overview-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .detail {
            padding-top: 30px;
            max-width: 80%;
        }
    }

}