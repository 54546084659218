.home-starWar {
	--fsTit: 80px;
	--fsDetail: 30px;
	--fsTitOverview: 49px;
	--fsDetailOverView: 18px;
	--maxHeight: 1163px;
	--widthOverView: 1500px;
	--paddingOverview: 150px 25px;
	--pbContentOverview: 115px;
	color: #fff;

	.container {
		padding: 0 250px;
	}

	.slogan {
		font-family: Montserrat-Bold;
		font-size: 48px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: #c51313;
		max-width: 800px;
		margin: 0 auto;
		margin-top: 200px;
		margin-bottom: 200px;
	}
	.star {
		&-concept {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);
			img {
				// width: 100%;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&-color {
			img {
				width: 100%;
			}
		}
		&-ref {
			padding-bottom: 400px;
			background-repeat: no-repeat;
			background-size: contain;
			&-img {
				display: flex;
				flex-direction: column;
				gap: 350px;
			}
		}
	}

	.imgBg {
		img {
			width: 100%;
		}
	}
	.img-double {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		padding-bottom: 300px;
		img {
			// width: 50%;
		}
	}

	@media screen and (max-width: 1500px) {
	}
	@media screen and (max-width: 1024px) {
		.container {
			padding: 0 50px;
		}
		.img-double {
			padding-bottom: 150px;
			flex-direction: column;
			img {
				width: 70%;
			}
		}
		.star {
			&-concept {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 70px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding: 0 30px;
		}
	}
	@media screen and (max-width: 576px) {
	}
}
