.footer-container {
	min-width: 1000px;
	width: 100%;
	height: 185px;
	background-color: black;
	display: flex;
	align-items: center;
	color: white;
	justify-content: space-between;
	.left {
		margin-left: 60px;
		.logo {
			width: 100px;
		}
		.sns-left {
			font-family: "GothamRoundedBook";
			font-size: 18px;
			display: flex;
			gap: 24px;
			display: none;
			.tab {
				cursor: pointer;
				position: relative;
			}
			.tab:before {
				content: "";
				position: absolute; /*부모 요소에는 position: relative를 주어야 한다.*/
				background-color: #fff;
				height: 2px; /*밑줄 굵기*/

				width: 0; /*초기에 보이지 않도록*/
				bottom: 0; /*a태그 아래에 위치시키기*/
				transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/

				/*가상 요소를 가운데로 이동시키기*/
				left: 50%;
				transform: translateX(-50%);
			}
			.tab:hover:before {
				width: 100%;
			}
		}
	}
	.right {
		margin-right: 60px;
		.sns {
			font-family: "GothamRoundedBook";
			font-size: 18px;
			display: flex;
			gap: 24px;
			.tab {
				cursor: pointer;
				position: relative;
			}
			.tab:before {
				content: "";
				position: absolute; /*부모 요소에는 position: relative를 주어야 한다.*/
				background-color: #fff;
				height: 2px; /*밑줄 굵기*/

				width: 0; /*초기에 보이지 않도록*/
				bottom: 0; /*a태그 아래에 위치시키기*/
				transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/

				/*가상 요소를 가운데로 이동시키기*/
				left: 50%;
				transform: translateX(-50%);
			}
			.tab:hover:before {
				width: 100%;
			}
		}
		.contact {
			font-family: "GothamRoundedBook";
			font-size: 18px;
			line-height: 1.4;
			margin-top: 24px;
		}
	}
	@media screen and (max-width: 1000px) {
		min-width: 1px;
	}
	@media screen and (max-width: 767px) {
		flex-direction: column;
		// height: 235px;
		align-items: flex-start;
		.left {
			margin-left: 0;
			padding: 0 15px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo {
				width: 64px;
				height: 64px;
			}
			.sns-left {
				display: flex;
			}
		}
		.right {
			margin-right: 0px;
			padding-bottom: 30px;
			text-align: left;
			.contact {
				font-size: 13px;
				padding-left: 15px;
				line-height: 1.5;
				margin-top: 24px;
			}
		}
		.right .sns {
			display: none;
			justify-content: center;
		}
	}
}
