// ** GothamRounded
@font-face {
    font-family: "GothamRoundedBook";
    font-style: normal;
    font-weight: 500;
    src: url("../Fonts/GothamRounded-Book.otf");
  }
@font-face {
    font-family: "GothamRoundedLigth";
    font-style: normal;
    font-weight: 400;
    src: url("../Fonts/GothamRounded-Light.otf");
  }
@font-face {
    font-family: "GothamRoundedMedium";
    font-style: normal;
    font-weight: 500;
    src: url("../Fonts/GothamRounded-Medium.otf");
  }
@font-face {
    font-family: "GothamRoundedBold";
    font-style: normal;
    font-weight: 500;
    src: url("../Fonts/GothamRounded-Bold.otf");
  }

// ** AppleGothic
@font-face {
    font-family: "AppleGothic";
    font-style: normal;
    font-weight: 500;
    src: url("../Fonts/applegothic.ttf");
  }

// ** GmarketSans
@font-face {
    font-family: "GmarketSans";
    font-style: normal;
    src: url("../Fonts/GmarketSansMedium.otf");
    font-weight: normal;
}
// ** Pretendard
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    src: url("../Fonts/Pretendard-Regular.otf");
    font-weight: normal;
}
@font-face {
    font-family: "Montserrat-Bold";
    font-style: normal;
    src: url("../Fonts/Montserrat-Bold.otf");
}
@font-face {
    font-family: "S-CoreDream-4";
    font-style: normal;
    src: url("../Fonts/SCDream4.otf");
}
@font-face {
    font-family: "S-CoreDream-6";
    font-style: normal;
    src: url("../Fonts/SCDream6.otf");
}
@font-face {
    font-family: "SamsungSans-Bold";
    font-style: normal;
    src: url("../Fonts/SamsungSans-Bold.ttf");
}
@font-face {
    font-family: "FuturaLTProBold";
    font-style: normal;
    src: url("../Fonts/FuturaLTProBold.otf");
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    src: url("../Fonts/Pretendard-Bold.otf");
    font-weight: bold;
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    src: url("../Fonts/Pretendard-Light.otf");
    font-weight: 300;
}

// ** NotoSerifCJKkr
@font-face {
  font-family: "NotoSerifCJKkr";
  src: url("../Fonts/NotoSerifKR-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

// ** AveriaSerif
@font-face {
  font-family: "AveriaSerif";
  src: url("../Fonts/AveriaSerif-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
// ** NotoSansKR
@font-face {
  font-family: "NotoSansCJKkr";
  src: url("../Fonts/NotoSansKR-Thin.otf");
  font-weight: 300;
  font-style: normal;
}

// ** NotoSansKR
@font-face {
  font-family: "SamsungSharpSans";
  src: url("../Fonts/samsungsharpsans-bold.otf");
  font-weight: bold;
  font-style: normal;
}

