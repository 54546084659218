.ise2023 {
	img {
		display: block;
		width: 100%;
	}
	.main-bg {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.overview {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		padding: 0 11em;
		gap: 1em;
		.top {
			font-family: SamsungSharpSans;
			font-size: 1em;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 4;
			letter-spacing: normal;
			text-align: center;
			color: #0a0111;
		}
		.bottom {
			font-family: Pretendard;
			font-size: 0.8em;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: center;
			color: #262326;
		}

		@media screen and (max-width: 1280px) {
			font-size: 25px;
		}

		@media screen and (max-width: 1024px) {
			font-size: 20px;
			padding: 0 5em;
		}

		@media screen and (max-width: 500px) {
			font-size: 20px;
			padding: 0 2em;
		}
	}

	.section-white {
		font-size: 25px;
		padding: 4em 0 4em;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		gap: 6em;
		align-items: center;

		.horizontal-block {
			width: 40%;
			height: 2px;
			background-color: #2038e1;
		}
		@media screen and (max-width: 1280px) {
			font-size: 20px;
		}
	}

	.keyword-section {
		background-color: #e3e3e3;
		font-size: 25px;
		padding: 4em 0 4em;
		display: flex;
		flex-direction: column;
		gap: 6em;
		align-items: center;

		.img {
			display: flex;
			gap: 1em;

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				.text {
					font-family: Pretendard;
					font-size: 24px;
					font-weight: 300;
					font-stretch: normal;
					font-style: normal;
					line-height: 5;
					letter-spacing: normal;
					text-align: center;
					color: #0a0111;
				}
			}
		}
		@media screen and (max-width: 1280px) {
			font-size: 20px;
			.img {
				.item {
					.text {
						font-size: 14px;
					}
				}
			}
		}
		@media screen and (max-width: 1024px) {
			font-size: 25px;
			.img {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				.item {
					width: calc(50% -  2em);
					.text {
						font-size: 20px;
						width: 100%;
						word-break: keep-all;
					}
				}
			}
		}
		@media screen and (max-width: 768px) {
			font-size: 25px;
			.img {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				.item {
					width: 60%;
					.text {
						font-size: 20px;
						width: 100%;
						word-break: keep-all;
					}
				}
			}
		}
	}

	.color-section {
		background-color: #e3e3e3;
		font-size: 25px;
		padding: 4em 0 5em;
		display: flex;
		flex-direction: column;
		gap: 6em;
		align-items: center;
		@media screen and (max-width: 1280px) {
			font-size: 20px;
		}
	}

	.key-visual-section {
		background-color: #000;
		font-size: 25px;
		padding: 4em 0 8em;
		display: flex;
		flex-direction: column;
		gap: 6em;
		align-items: center;

		.top {
			font-family: SamsungSharpSans;
			font-size: 30px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 4;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
		}

		@media screen and (max-width: 1280px) {
			font-size: 20px;
		}
	}
}
