.about-container {
	--marginMainImage: 180px;
	--heightSpicialImage: 754px;
	min-width: 1400px;
	max-width: 3200px;
	width: 100%;

	// display: flex;
	// justify-content: center;
	.group {
		width: 100%;

		.about-main {
			width: 100%;
			// max-height: 700px;
			object-fit: cover;
			margin-top: var(--marginMainImage);
			padding: 0 15%;
			margin-bottom: 100px;
		}

		.carousel-container {
			margin-top: 50px;
			margin-bottom: 100px;
		}

		.list-character {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0 15%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&-image {
				max-width: 921px;
				margin-top: 20px;
				// max-height: 892px;
			}

			.character0 {
				// margin-bottom: var(--marginSpecialImage);
				margin-right: 50px;
				max-width: 921px;
				// height: var(--heightSpicialImage);
			}
		}

		.discription {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 726px;
			margin-bottom: 114px;
			gap: 380px;

			.menu-box {
				font-family: "GothamRoundedMedium";
				font-size: 60px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: flex-start;
				color: gray;
				line-height: 1.6;

				.tap {
					cursor: pointer;
					position: relative;
				}

				.tap:before {
					content: "";
					position: absolute;
					/*부모 요소에는 position: relative를 주어야 한다.*/
					background-color: gray;
					height: 2px;
					/*밑줄 굵기*/

					width: 100%;
					bottom: 0;
					/*a태그 아래에 위치시키기*/

					/*가상 요소를 가운데로 이동시키기*/
					left: 50%;
					transform: translateX(-50%);
				}

				.tap:hover:before {
					background-color: #00693e;
				}

				.tap:hover {
					color: #00693e;
					font-family: "GothamRoundedMedium";
				}

				a input:checked+label {
					color: #00693e;

					&:before {
						background-color: #00693e;
					}
				}
			}

			.en {
				font-family: "GothamRoundedBook";
				font-size: 20px;
				font-weight: 300;
			}

			.ko {
				font-family: "Noto Sans KR", sans-serif;
				font-size: 18px;
				font-weight: 600;
				line-height: 1.74;
				letter-spacing: 0px;
			}

			.text-box {

				font-weight: 600;
				line-height: 1.74;
				width: 430px;

				p {
					white-space: pre-line
				}
			}
		}

		.we-are-looc-reative {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 200px;

			.bk-logo {
				width: 380px;
				margin-bottom: 36px;
			}

			span {}

			.ko {
				font-family: "Noto Sans KR", sans-serif;
				font-size: 18px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0px;
			}

			.en {
				font-family: "GothamRoundedBook";
				font-size: 18px;
			}

			.sub-text {
				margin-bottom: 10px;
			}

			.end-text {}
		}
	}

	@media screen and (max-width: 1400px) {
		min-width: 1px;

		.group {
			.discription {
				gap: 150px;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		--marginMainImage: 80px;
		min-width: 1px;

		.group {
			.discription {
				gap: 0px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		--heightSpicialImage: 640px;

		.group .carousel-container {
			margin-top: 40px;
			margin-bottom: 50px;
		}

		.group {
			.we-are-looc-reative {
				text-align: center;
			}

			overflow: hidden;

			.about-main {
				scale: 1;
				transform-origin: center;
				margin-bottom: 50px;
			}
		}

		.group .discription {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;
			height: 100%;
			margin-bottom: 70px;
			padding: 0 15px;
			gap: 20px;

			.text-box {
				p {
					font-weight: 600;
					line-height: 1.6;
					letter-spacing: 0;
				}
			}
		}

		.group .discription .menu-box {
			font-size: 30px;
		}

		.group .discription .text-box {
			font-size: 18px;
			line-height: 1.74;
			letter-spacing: 1.5px;
			width: 100%;
		}

		.group .we-are-looc-reative .bk-logo {
			width: 80%;
			margin-bottom: 16px;
		}

		.group .we-are-looc-reative {
			margin-bottom: 70px;
		}
	}
}