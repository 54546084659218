.wrapper_page {
	align-items: center;
	color: #fff;
	display: flex;
	height: 100%;
	left: 0;
	margin: 0 auto;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.2s;
	width: 70%;
	z-index: 0;
	z-index: 5;
}

.works_ {
	width: 100%;
	height: 100%;
	padding: 43px 20px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	.logo-title {
		width: 100%;
		height: 40px;

		color: black;

		.logo {
			width: 40px;
			height: 40px;
			float: left;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.title {
			padding-top: 5px;
			float: left;

			p {
				font-family: "GothamRoundedLigth";
				font-size: 14px;
			}

			.bold {
				font-size: 16px;
				font-weight: bold;
				font-family: "GothamRoundedBold";
			}

			// margin-left: 10px;
		}
	}

	.menu-tab {
		ul {
			list-style: none;

			li {
				font-size: 11px;
			}
		}

		width: 100%;
		color: #b4b4b4;
		font-size: 11px;
		margin-top: 20px;
		margin-bottom: 10px;
		// height: 35px;
		// overflow-y: hidden;

		.selectBox {
			width: 100%;
			height: auto;
			display: none;
		}

		ul {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;

			li {
				padding: 2px 5px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				cursor: pointer;
				font-family: "GothamRoundedMedium";
				-webkit-transition: all 0.2s;
				-o-transition: all 0.2s;
				transition: all 0.2s;

				&:hover {
					color: rgba(0, 0, 0, 0.5);
				}
			}

			.select {
				color: black;

				&:hover {
					color: black;
				}
			}
		}
	}

	.card-all-wrapper {
		width: 100%;
		height: calc(100% - 100px);

		.card-wr {
			width: 100%;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			position: relative;
			animation-duration: 0.2s !important;

			&:hover {
				&::-webkit-scrollbar-thumb {
					background-color: #222;
				}
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
				background-color: transparent;
				border-radius: 0.5rem;
			}

			&::-webkit-scrollbar {
				width: 1px;
				background-color: transparent;
			}

			&::-webkit-scrollbar-thumb {
				// background-color: $subColor;
				background-color: #e7e7e7;
				border-radius: 0.5rem;
			}

			&.ALL,
			&.INTERACTIVE,
			&.DIGITAL,
			&.EXHIBITION,
			&.FILM,
			&.LAB {
				.card {
					-webkit-animation-name: show;
					animation-name: show;
					-webkit-animation-duration: 0.2s !important;
					animation-duration: 0.2s !important;

					>* {
						-webkit-transition-delay: 0s !important;
						-o-transition-delay: 0s !important;
						transition-delay: 0s !important;
						animation-duration: 0s !important;
					}
				}

				@-webkit-keyframes show {
					from {
						opacity: 0;
						-webkit-transform: translateY(-25px);
						transform: translateY(-25px);
					}

					to {
						opacity: 1;
						-webkit-transform: translate(0, 0);
						transform: translate(0, 0);
					}
				}

				@keyframes show {
					from {
						opacity: 0;
						-webkit-transform: translateY(-25px);
						transform: translateY(-25px);
					}

					to {
						opacity: 1;
						-webkit-transform: translate(0, 0);
						transform: translate(0, 0);
					}
				}
			}

			.scroll {
				width: 100%;
				height: auto;
				position: absolute;
				// left: -1.3%;
			}

			.card-pd {
				width: 30%;
				position: relative;
				margin: 1%;
				float: left;

				&::before {
					content: "";
					display: block;
					padding-top: 56.25%;
				}
			}

			.inner-flex {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.card {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;

				.contents-hide {
					position: absolute;
					z-index: 5;
					-webkit-transition: all 0.2s !important;
					-o-transition: all 0.2s !important;
					transition: all 0.2s !important;
					// transition-duration: 0.1s !important;
					transition-delay: 0s !important;
					width: 100%;
					height: 100%;
					bottom: 0;
					position: absolute;
					// background-color: rgba($color: #f05229, $alpha: 0);
					color: transparent;
					// // color: white;
					// background-color: #0000007c;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					padding: 10px 20px;
					display: flex;
					align-items: center;

					>* {
						-webkit-transition: all 0s !important;
						-o-transition: all 0s !important;
						transition: all 0s;
						transition-duration: 0s !important;
						transition: all 0s !important;
						transition-delay: 0s !important;
					}
				}

				.contents-img {
					width: 100%;
					height: 100%;
					background-color: black;
					background-size: cover;
					background-position: center;
				}

				transition: 400ms all;

				&:hover {
					-webkit-transform: translateY(-5px);
					-ms-transform: translateY(-5px);
					transform: translateY(-5px);

					.contents-hide {
						height: 100%;
						padding: 20px;
						// background-color: rgba($color: #000000, $alpha: 0.5);
						color: white;

						.title {
							white-space: normal;
							text-overflow: inherit;
							overflow: inherit;
						}
					}

					.desc {
						font-family: "GothamRoundedBold";
						font-size: 11px;
						text-align: left;
					}

					.partners {
						margin-top: 20px;
						font-family: "GothamRoundedBold";
						font-size: 18px;
						font-weight: bold;
					}

					.title {
						font-family: "GothamRoundedLigth";
						font-size: 18px;
						// margin-top: -10px;
					}

					cursor: pointer;
				}

				&:active {
					-webkit-transform: translateY(0px);
					-ms-transform: translateY(0px);
					transform: translateY(0px);
				}

				.desc {
					font-family: "GothamRoundedBold";
					font-size: 11px;
					text-align: left;
				}

				.partners {
					margin-top: 20px;
					font-family: "GothamRoundedBold";
					font-size: 18px;
					font-weight: bold;
				}

				.title {
					font-family: "GothamRoundedLigth";
					font-size: 18px;
					// margin-top: -10px;
				}
			}
		}
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		height: 40px;
		overflow: hidden;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: black;

		p {
			margin: 0 5px;
			color: #b4b4b4;
			cursor: pointer;
			font-family: "GothamRoundedMedium";
			font-size: 11px;
			padding-bottom: 5px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}

		p:nth-child(1) {
			margin-left: 15px;
			cursor: pointer;
		}

		img {
			// height: 45px;
		}
	}
}

.contact-works {
	width: 150px;
	height: 50px;
	background-color: #000000;
	margin: 30px auto;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-family: "GothamRoundedMedium";

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}

	&:active {
		transform: translateY(5px);
	}

	// fos
	a {}
}

.email-popup {
	.connect-with-us {
		color: white;
	}

	&.works-popup {
		position: fixed;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 50%;
		transform: translateY(-50%);
		box-shadow: 0px 0px 500px gray;
	}
}

@media (max-height: 600px) {
	.email-popup-inner-scroll {
		height: 60vh !important;
	}
}

@media (max-width: 1024px) {
	.email-popup {
		// position: fixed;
		position: relative;
		bottom: 0;
		z-index: 30;
		left: 0;
		width: 100vw;
		left: -27%;
		height: 100vh;
		top: 0;

		.orange-area {
			// padding-top: 20px;

			.connect-with-us {
				font-size: 25px;
			}
		}

		.X-btn {
			left: -5px;
			top: 0;
			background-color: transparent;

			span {
				font-size: 20px !important;
				color: white;
			}

			&.out {
				display: none;
			}

			&.inner {
				display: flex;
			}
		}

		.email-popup-inner {
			height: 100%;
		}

		.email-popup-inner-scroll {
			height: 100% !important;

			.warning-text {
				font-size: 13px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.wrapper_page {
		width: 100%;
	}
}

@media (max-width: 400px) {
	.warning-text {
		font-size: 11px !important;
	}

	.popup-contents-input {
		input {
			font-weight: bold;
		}
	}

	.Dropdown-placeholder {
		font-size: 13px !important;
		white-space: nowrap;
		font-weight: 100;
	}
}

@media (max-height: 660px) {
	.popup-contents-inquiry {
		height: 42% !important;
	}

	.info-use {
		margin-top: -5%;
	}

	.warning-text {
		bottom: 29% !important;
	}
}

@media (max-height: 510px) {
	.email-popup {
		height: 510px !important;
	}
}

@media (min-width: 1750px) {
	.card-pd {
		width: 24% !important;
		margin: 0.5% !important;
	}

	.contents-hide {
		padding: 20px !important;
	}
}

@media (max-width: 1140px) {
	.works- {
		.menu-tab {
			.selectBox {
				display: block;
			}
		}
	}

	.card-pd {
		width: 45% !important;
		margin: 2.5% !important;
	}
}

@media (max-width: 720px) {
	.works- {
		padding: 43px 20px;
	}

	.menu-tab {
		// height: 200px;
		margin-top: 20px !important;

		.selectBox {
			visibility: visible;
		}

		ul {
			display: block !important;

			li {
				text-align: left;
			}
		}
	}

	.card-pd {
		width: 90% !important;
		margin: 5% !important;

		.contents-hide {
			color: white !important;
			background-color: #0000006b !important;
			height: 110px !important;
			bottom: 0;

			.desc {
				font-size: 14px !important;
			}

			.partners {
				margin-top: 5px !important;
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		display: none;
		opacity: 0;
	}
}

.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;

	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-name: opac;
	animation-name: opac;

	@-webkit-keyframes opac {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1 !important;
		}
	}

	@keyframes opac {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1 !important;
		}
	}

	.btn-close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		text-align: center;
		position: absolute;
		text-align: center;
		background-color: #00693e;
		border-radius: 99;
		box-shadow: none;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
		z-index: 999;
		top: 1%;
		right: 0%;

		// color: #000;
		.css-i4bv87-MuiSvgIcon-root {
			width: 40px;
			height: 40px;
			color: #fff;
		}

		// @media screen and (max-width: 1400px) {
		// 	right: 7% !important;
		// 	}
		@media (max-width: 767px) {
			// right: 9% !important;
			width: 30px;
			height: 30px;
		}

		span {
			transition: all 0.5s;
		}

		&:hover {
			span {
				transform: rotate(360deg);
			}
		}
	}

	&-inner {
		opacity: 1;
		width: 100%;
		height: 100%;
		margin: auto;
		background-color: #fff;
		z-index: 12;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		box-sizing: border-box;
		// padding: 30px 30px 0;

		@media (max-width: 590px) {
			width: 100%;
		}
	}


	&-contents {
		// padding-top: 30px;
		width: 100%;
		height: 100%;
		margin-top: 0;
		overflow-x: hidden;
		overflow-y: auto;
		position: relative;
		box-sizing: border-box;

		&:hover {
			&::-webkit-scrollbar-thumb {
				//   background-color: #000000;
			}
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			background-color: transparent;
			border-radius: 0.5rem;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			// background-color: $subColor;
			background-color: #e7e7e7;
			border-radius: 0.5rem;
		}

		.scroll-inners {
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			height: auto;
			box-sizing: border-box;
			word-break: break-all;
		}
	}
}

.mainImage {
	width: 100%;
	height: auto;

	img {
		width: 100%;
		height: auto;
	}
}

.mainDesign {

	// padding-top: 30px;
	img {
		width: 100%;
	}
}

.overView {
	margin: 40px auto;
	color: #000000;
	padding: 0 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	.left-title {
		flex: 3;
	}

	.right-title {
		flex: 5;
		display: flex;
		align-items: flex-end;
	}

	.overViewWrap {
		display: flex;
		flex: 1;
		width: 100%;
		// margin-top: 40px;
		margin-bottom: 45px;
	}

	.title {
		white-space: nowrap;
		font-size: 2.5rem;
	}

	.border {
		width: 100%;
		// margin-left: 20px;
		border: none;
		height: 100%;
		border-bottom: 1px solid #000000;
		margin-bottom: 0;
	}

	.type {
		font-size: 12px;
		font-weight: 700;
		font-family: "GothamRoundedBold";
		margin-bottom: 15px;
	}

	.company {
		font-family: "GothamRoundedLigth";
		font-weight: bold;
		font-size: 25px;

		@media screen and (max-width: 767.98px) {
			font-size: 15px;
		}
	}

	.name {
		font-size: 25px;
	}
}

.mainVideo {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;

	video {
		width: 100%;
		height: auto;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	body {
		width: 100%;
	}
}

.description {
	width: 100%;
	margin: 40px auto;
	color: #000000;
	font-size: 1.5rem;
	line-height: 2rem;
}

.detailInner {
	width: 100%;
	margin: 20px auto;
	color: #000000;
	margin-top: 40px;

	.title-wrapper {
		width: 100%;
		display: flex;
		font-size: 1.5rem;

		.title-left {
			font-weight: bold;

			&::after {
				content: "|";
				margin: 0 10px;
			}
		}
	}

	.description {
		width: 100%;
		padding: 0;
		margin: 20px 0;
		line-height: 1.6rem;
	}

	.imageWrapper {
		width: 100%;
		margin: 30px auto;
		margin-bottom: 60px;

		// span {
		//     height: 100%;
		//     display: block;
		// }
		img {
			width: 100%;
			// height: 100%;
		}
	}
}