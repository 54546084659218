.introSmall {
	&-star-war {
		padding: 200px 0;
	}
	&-title1 {
		font-family: Montserrat-Bold;
		font-size: 60px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		min-width: 200px;
		width: 330px;
		margin-bottom: 40px;
	}
	&-title2 {
		font-family: Montserrat-Bold;
		font-size: 30px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #ece9ea;
		margin-bottom: 22px;
	}
	&-detail {
		p {
			font-family: Pretendard;
			font-size: 20px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.8;
			letter-spacing: normal;
			text-align: left;
			color: #888;
			max-width: 1100px;
		}
	}
	@media screen and (max-width: 1024px) {
	}
}
