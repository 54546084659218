.ces2023opening {
	img {
		display: block;
		width: 100%;
	}
	.main-bg {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover;
		}
	}

    .overview {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		font-size: 30px;
		padding: 4.5em 10em 5em;
		gap: 1em;
		.top {
			font-family: SamsungSharpSans;
			font-size: 1.2em;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 4;
			letter-spacing: normal;
			text-align: left;
			color: #0a0111;
			word-break: break-all;
		}
		.bottom {
			font-family: Pretendard;
			font-size: 0.6em;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #262326;
			word-break: break-all;
		}

		@media screen and (max-width: 1280px) {
			font-size: 25px;
		}
        @media screen and (max-width: 1024px) {
            gap:.5em;
            padding: 1.5em 2em 2em;
			font-size: 30px;
		}
        @media screen and (max-width: 768px) {
			gap:.5em;
            padding: 1.5em 2em 2em;
			font-size: 28px;
			.top{
				line-height: 1.4;
			}
			
		}
        @media screen and (max-width: 500px) {
            padding: 1.5em 1em 2em;
            gap:.5em;
			font-size: 16px;
			
			
		}
	}

    .tech-img{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        img{
            width: 100%;
        }

        @media screen  and (max-width: 768px) {
            grid-template-columns: 50% 50%;
        }
        @media screen  and (max-width: 500px) {
            grid-template-columns: 100%;
        }
    }
}
