.skeleton{
    // background: linear-gradient(45deg,gray, white);

    background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
    // border-radius: 1rem;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 1s infinite;
    width: "100%";
    padding-bottom: "56.25%";
    // height: "100%"
}
.fade-in{
    animation: fadein 1.5s;
    -moz-animation: fadein 1.5s; /* Firefox */
    -webkit-animation: fadein 1.5s; /* Safari and Chrome */
    -o-animation: fadein 1.5s; /* Opera */
}

@keyframes load {
    100% {
        background-position: -100% 0;
    }
}
@keyframes shine {
    to {
      background-position:
        100% 10px,
        5px 10px,
        5px 30px,
        5px 50px;
    }
  }

  
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}