html {
	scroll-behavior: smooth;
  }
header {
	.header-container {
		position: fixed;
		// top: 0; // 네비게이션
		min-width: 1400px;
		max-width: 3200px;
		width: 100%;
		display: flex;
		height: 80px;
		justify-content: space-between;
		z-index: 999;
		align-items: center;
		@media screen and (max-width: 767px) {
			height: 70px;
		}
		.logo {
			display: flex;
			z-index: 9;
			.logo-img {
				height: 64px;
				width: 64px;
				margin: 9px 30px;
				@media screen and (max-width: 767px) {
					margin: 9px 15px;
				}
			}
		}
		.list-menu {
			z-index: 99;
			.menus {
				&_list {
					display: flex;
					gap: 88px;
				}
				
	
				a {
					color: #111111;
					text-transform: uppercase;
					.tab {
						font-family: "GothamRoundedMedium";
						font-size: 18px;
						position: relative;
					}
					.tab:before {
						content: "";
						position: absolute; /*부모 요소에는 position: relative를 주어야 한다.*/
						background-color: 	#111111;
						height: 2px; /*밑줄 굵기*/
		
						width: 0; /*초기에 보이지 않도록*/
						bottom: 0; /*a태그 아래에 위치시키기*/
						transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
		
						/*가상 요소를 가운데로 이동시키기*/
						left: 50%;
						transform: translateX(-50%);
					}
					.tab:hover:before {
						width: 100%;
					}
				}
				a {
					.activeMenu {
						position: relative !important;
					}
					.activeMenu:before {
						content: "";
						position: absolute; /*부모 요소에는 position: relative를 주어야 한다.*/
						background-color: #111111;
						height: 2px; /*밑줄 굵기*/
			
						width: 100%; /*초기에 보이지 않도록*/
						bottom: 0; /*a태그 아래에 위치시키기*/
						transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
			
						/*가상 요소를 가운데로 이동시키기*/
						left: 50%;
						transform: translateX(-50%);
					}
				}
				.homeSection  {
					color: rgb(255, 255, 255);
					.tab:before {
						content: "";
						background-color: 	#fff;
					}
				
				}
			}
		}
		
		.language {
			font-family: "GothamRoundedBook";
			font-size: 18px;
			cursor: pointer;
			display: flex;
			font-size: 18px;
			margin: 9px 30px;
			text-decoration: underline;
			text-underline-position: under;
			z-index: 999;
			@media screen and (max-width: 767px) {
				margin: 9px 15px;
			}
			// text-underline-offset : 1px;
			
			span {
				font-family: "GothamRoundedBook";
				font-size: 18px;
			}
			.openMenu {
				text-decoration: underline;
				color: #fff;
				text-underline-position: under;
			
			}
			.mobile-icon {
				display: none;
				.btn-toggle {
					position: relative;
					cursor: pointer;
					z-index: 13333;
					margin-bottom: 15px;
					span {
					  display: block;
					  width: 30px;
					  height: 2px;
					  margin-bottom: 7.5px;
					  background: #222;
					  transform-origin: -1px -5px;
					  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
						background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
			  
					  &:first-child {
						transform-origin: 0% 0%;
					  }
					  &:nth-last-child(2) {
						transform-origin: 0% 100%;
					  }
					}
					&.open {
						margin-right: 0px;
					  span {
						height: 1px;
						opacity: 1;
						transform: rotate(45deg) translate(1px, -6px);
						background: #fff !important;
			  
						&:nth-child(2) {
						  opacity: 0;
						  transform: rotate(0deg) scale(0.2, 0.2);
						}
			  
						&:nth-child(3) {
						  transform: rotate(-45deg) translate(-1px, 3px);
						}
					  }
					}
					
				  }
			}
		}
	
		@media screen and (max-width: 1400px) {
			min-width: 1px;
		}
		@media screen and (max-width: 1023px) {
			.menus {
				&_list {
					gap: 50px
				}
				
			}
		}
		@media screen and (max-width: 767px) {
			.list-menu {
				display: none;
			}
			.language { 
				flex-direction: column;
				justify-content: center;
				align-items: center;
				
				.mobile-icon {
					display: block;
					padding-top: 10px;
				}
				 .langVisible{
					display: block ;
					
				}
				.langHidden{
					display: none ;
				}
			}
		}
	}
	.activeHeader {
		background: #fff;
		box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
		.list-menu .menus .menus_list .homeSection {
			color: #111;
		}
		.language .mobile-icon .btn-toggle.open {
			span {
				background-color: #111!important;
			}
		}
		.language .openMenu {
			color: #111!important;
		}
		.list-menu .menus .menus_list .homeSection .tab:before {
			content: "";
			background-color: #111;
		}
	}
	.openMenu {
		background-color: transparent;
		box-shadow: none;
		.list-menu .menus .menus_list .homeSection {
			color: #fff;
		}
		.language .mobile-icon .btn-toggle.open {
			span {
				background-color: #fff!important;
			}
		}
		.language .openMenu {
			color: #fff!important;
			padding-right: 3px;
			position: fixed;
    		top: 15%;
			@media screen and (max-width: 767px) {
				padding-right: 15px;
				text-decoration: none;
				.langVisible {
					font-size: 21px;
					color: #00693e !important
				}
			}
		}
		
	}
	.menu-list-mobile {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 103;
		width: 100%;
		height: 100%;
		transform: translateX(100%);
		transition: 400ms;
		display: grid;
		grid-template-columns: 10% auto;
		.menus {
			background-color: #111;
			width: 100%;
			height: 100%;
			&_list {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 0 15px;
				position: absolute;
				top: 15%
			}
			a {
				color: #fff;
				font-size: 22px;
				padding:0;
				padding-bottom: 50px;
				font-family: "GothamRoundedBook";
			}
			.activeMenu {
				text-decoration: underline !important;
			}
		}
	}
	.open{
		transform: translateX(0%) ;
	}
	
}