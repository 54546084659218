.digilloca{
    img {
		display: block;
	}

    .main-bg {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover;
		}
	}
    .top{
        font-family: GmarketSans, sans-serif;
    }
    .bottom{
        font-family: NotoSansCJKkr, sans-serif;
    }
    .overview {
        padding: 150px 0 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item{
            margin: 0 auto;
            width: 80%;
            display: flex;
            gap: 30px;
            margin-bottom: 135px;
            
    flex-direction: column;

            .top{
                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.02;
                letter-spacing: normal;
                text-align: left;
                color: #333;
                width: 100%;
                flex-grow: 2;
            }

            .bottom{

                font-size: 18px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.89;
                letter-spacing: normal;
                text-align: left;
                color: #333;
            }
        }

        img{
            width: 80%;
            
            &+img{
              margin-bottom: 50px;  
            }
        }


        @media screen and (max-width: 1024px) {
            padding: 100px  0 75px;

            .item{
                width: 92%;
                gap: 30px;
                .top{
                    font-size: 26px;
                    word-break:break-all;
                }
                .bottom{
                    font-size: 16px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            padding: 100px  0 75px;
            
            .item{
                width: 92%;
                gap: 30px;
                flex-direction: column;
                margin-bottom: 100px;
                .top{
                    font-size: 26px;
                    word-break:break-all;
                }
                .bottom{
                    font-size: 16px;
                }
            }
        }

        @media screen and (max-width: 500px) {
            padding: 50px  0 25px;
            
            .item{
                width: 92%;
                gap: 30px;
                flex-direction: column;
                margin-bottom: 50px;
                .top{
                    font-size: 26px;
                    word-break:break-all;
                }
                .bottom{
                    font-size: 16px;
                }
            }
        }
    }

    .key-visual{
        padding: 150px 0 150px;

        .content{
            margin-bottom: 110px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 60px;

            .top{
   
                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.02;
                letter-spacing: normal;
                text-align: left;
                color: #333;
            }
            .bottom{

                font-size: 18px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.89;
                letter-spacing: normal;
                text-align: center;
                color: #333;
                max-width: 80%;
            }
        }
        .item{
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 80%;
                max-width: 1573px;
            }
        }
    }

}