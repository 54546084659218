.intro-ssqled {
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	&-container {
		width: 72.5%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 60px;
		padding: 150px 0;
		.title {
			color: #000f3b;
			font-family: SamsungSans-Bold;
			font-size: 32px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			color: #000f3b;
		}
		.detail {
			color: #11111111;
			font-family: AppleGothic;
			font-size: 18px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #111;
		}
        .center {
            text-align: center;
        }
	}
}
