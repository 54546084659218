.drbrain{
    background-color: #000;
    img {
		display: block;
	}

	.main-bg {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover;
		}
	}

    .overview{
        padding: 180px 0px 180px;
        background-color: #000;
        .item{
            display: flex;
            width: 80%;
            
            justify-content: center;
            // align-items: center;
            margin: 0 auto;

            .top{
                font-family: Pretendard;
                font-size: 34px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.41;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                width: 40%;
                text-transform: uppercase;
            }
            .bottom{
                font-family: Pretendard;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: left;
                color: #999;
                width: 60%;
            }
        }

        @media screen and (max-width: 786px) {
            padding: 90px 0px 90px;
            .item {
                flex-direction: column;
                width: 100%;
                // padding: 0 10px;
                justify-content: center;
                align-items: center;

                .bottom{
                    width: unset;
                    text-align: center;
                    word-break: keep-all;
                    width: 90%;
                }
                .top{
                    width: unset;
                    word-break: keep-all;
                    text-align: center;
                }
            }
        }
    }
    .block{
        height: 180px;
        background-color: #000;
    }
    .realistic{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        img{
            width: 100%;
            object-fit: cover;
        }

        @media screen and (max-width: 786px) {
            grid-template-columns: 100%;
        }
    }

    .logo{
        display: grid;
        grid-template-columns: 1fr 1fr;

        img{
            width: 100%;
        }
    }

}