.samsungGalaxy {

    img,
    iframe {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &-overview {
        padding: 250px 0 180px;

        @media screen and (max-width: 1080px) {
            padding: 70px 0 60px;
        }

        @media screen and (max-width: 767px) {
            padding: 30px 0 30px;
        }

        &-imgs {
            padding-left: 120px;
            display: grid;
            grid-template-columns: 60% auto;
            column-gap: 143px;
            height: 656px;
            overflow: hidden;

            .img_1 {
                max-width: 850px;
            }

            .img_2 {
                max-width: 760px;
                transform: translateX(1%);
            }

            @media screen and (max-width: 767px) {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                column-gap: 143px;
                height: 100%;
                overflow: hidden;
                padding-left: 20px;
                padding-right: 20px;

                .img_1 {
                    max-width: 850px;
                }

                .img_2 {
                    padding-top: 30px;

                }
            }
        }

        &-design {
            max-width: 1400px;
            padding: 200px 25px 0;
            margin: 0 auto;

            .title {
                text-align: center;
                font-size: 60px;
                padding-bottom: 60px;
            }

            .img {
                height: 940px;
            }

            .content {
                display: flex;
                justify-content: space-between;
                padding-top: 150px;
                align-items: center;

                .title {
                    font-size: 60px;
                    text-align: left;
                    line-height: 1.3;
                }

                .detail {
                    font-size: 24px;
                    line-height: 1.7;
                }

                @media screen and (max-width: 1080px) {
                    padding-top: 40px;
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        font-size: 30px;
                    }

                    .detail {
                        font-size: 22px;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                padding: 60px 25px 0;

                .img {
                    max-height: 540px;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    padding-top: 50px;

                    .title {
                        font-size: 30px;
                        padding-bottom: 20px;

                        br {
                            display: none;
                        }
                    }

                    .detail {
                        font-size: 16px;
                        max-width: 80%;

                        br {
                            display: none;
                        }
                    }
                }
            }
        }


    }

    &-video {
        height: 100vh;
    }

    &-keyword {
        .title {
            font-size: 80px;
            padding: 150px 220px;

            @media screen and (max-width: 1080px) {
                padding: 50px 80px;
               font-size: 30px;
            }
            @media screen and (max-width: 768px) {
                padding: 20px 30px;
                font-size: 30px;
            }
        }

        .img_1 {
            padding-bottom: 150px;

            @media screen and (max-width: 1080px) {
                padding-bottom: 80px;
            }

            @media screen and (max-width: 767px) {
                padding-bottom: 40px;
            }
        }

        .img_3 {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &-conti {
        padding: 158px 0;

        .title {
            font-size: 80px;
            padding: 0px 220px;

        }

        .img {
            padding-top: 132px;
        }

        @media screen and (max-width: 767px) {
            padding: 58px 0;

            .title {
                font-size: 30px;
                padding: 0px 20px;

            }

            .img {
                padding-top: 42px;
            }
        }
    }

    &-performance {
        background: #f4f4f4;

        .content {
            padding: 140px 25px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .title {
                font-size: 80px;
            }

            .detail {
                font-size: 24px;
                line-height: 1.7;
            }

            @media screen and (max-width: 1080px) {
                padding: 60px 25px;

                .title {
                    font-size: 30px;
                }

                .detail {
                    font-size: 22px;
                }
            }
        }

        .img {
            display: grid;
            grid-template-columns: 30% auto;
            column-gap: 23px;
            padding: 0 50px;

            .left {
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 23px;

                img {
                    object-fit: cover;
                }
            }
        }

        .img-pro {
            padding-top: 150px;
        }

        .img-gifs {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 767px) {
            .content {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 30px;

                    br {
                        display: none;
                    }
                }

                .detail {
                    font-size: 16px;
                    max-width: 80%;
                    padding-top: 30px;

                    br {
                        display: none;
                    }
                }
            }
        }
    }

    &-slim {
        .content {
            padding: 140px 25px;
            max-width: 1400px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 40% auto;

            .title {
                font-size: 80px;
            }

            .detail {
                font-size: 24px;
                line-height: 1.7;
            }
        }

        @media screen and (max-width: 767px) {
            .content {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 30px;

                    br {
                        display: none;
                    }
                }

                .detail {
                    font-size: 16px;
                    max-width: 80%;
                    padding-top: 30px;

                    br {
                        display: none;
                    }
                }
            }
        }

        .imgs {
            display: grid;
            grid-template-columns: 30% auto;
            column-gap: 23px;
            padding: 0 50px;

            .left {
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 23px;

                img {
                    object-fit: cover;
                }
            }
        }

        .gif {
            padding-top: 150px;
        }

        .list-img {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &-content {
        padding: 180px 15px;
        text-align: center;
        font-size: 20px;
        line-height: 1.7;

        @media screen and (max-width: 1080px) {
            padding: 80px 25px;

            br {
                display: none;
            }
        }
    }

}