.contact-container {
	min-width: 1400px;
	max-width: 3200px;
	width: 100%;


	// display: flex;
	// justify-content: center;
	.group {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.contact-main {
			width: 120%;
			margin-top: 100px;
		}

		.contact-map {
			width: 100%;
		}

		.loocreative {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.title {
				font-family: "GothamRoundedMedium";
				font-size: 36px;
				margin-top: 200px;
				margin-bottom: 46px;
			}

			.ko {

				font-family: "Noto Sans KR", sans-serif;
				font-size: 20px;
				font-weight: 900;
				line-height: 0.4;
				letter-spacing: 0px;
			}

			.en {
				font-family: "GothamRoundedBook";
				font-size: 24px;
			}

			.sub-text {

				margin-bottom: 16px;
			}

			.end-text {
				margin-bottom: 0px;
			}


		}

		.company-profile {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 200px;

			.ko {
				font-family: "Noto Sans KR", sans-serif;
				font-size: 20px;
				font-weight: 900;
				line-height: 0.4;
				letter-spacing: 0px;
			}

			.en {
				font-family: "GothamRoundedBook";
				font-size: 24px;
			}

			.title {
				font-family: "GothamRoundedMedium";
				font-size: 36px;
				margin-top: 200px;
				margin-bottom: 46px;
			}

			.sub-text {
				margin-bottom: 16px;
			}

			.end-text {

				margin-bottom: 0px;
			}

			.under-line {
				text-decoration: underline;
				text-underline-position: under;
			}

			.btn-list {
				display: flex;
				margin-top: 46px;
				gap: 12px;

				span {
					font-family: "GothamRoundedBook";
					font-size: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 15px;
					background-color: black;
					color: white;
				}

				.sand {
					width: 98px;
					height: 30px;
				}

				.download {
					width: 144px;
					height: 30px;
				}
			}
		}
	}

	@media screen and (max-width: 1399px) {
		min-width: unset;
	}

	@media screen and (max-width: 767.98px) {
		text-align: center;

		.group {
			overflow: hidden;

			.contact-main {
				width: 80%;
				margin-top: 150px;
				transform-origin: center;
				scale: 2.5;
			}

			.contact-map {
				margin-top: 100px;
			}

			.loocreative {
				.title {
					margin-top: 110px;
					font-size: 24px;
					margin-bottom: 15px;
				}

				.sub-text {
					font-size: 16px;
					margin-bottom: 0;
				}

				.st1 {
					margin-bottom: 15px;
				}

				.end-text {
					font-size: 16px;
				}
			}

			.talent-recruitment {
				.title {
					margin-top: 70px;
					font-size: 24px;
					margin-bottom: 15px;
				}

				.sub-text {
					font-size: 16px;
				}

				.end-text {
					font-size: 16px;
				}

				.btn-list {
					margin-top: 20px;

					.sand {
						width: 144px;
					}
				}
			}

			.company-profile {
				margin-bottom: 70px;

				.title {
					margin-top: 70px;
					font-size: 24px;
					margin-bottom: 15px;
				}

				.sub-text {
					font-size: 16px;
				}

				.end-text {
					font-size: 16px;
				}

				.btn-list {
					margin-top: 20px;

					.sand {
						width: 144px;
					}
				}
			}
		}
	}
}