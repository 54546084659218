.home-must {
	--fsTit: 80px;
	--fsDetail: 30px;
	--fsTitOverview: 49px;
	--fsDetailOverView: 18px;
	--maxHeight: 1163px;
	--widthOverView: 1500px;
	--paddingOverview: 150px 25px;
	--pbContentOverview: 115px;
	color: #fff;

	.imgBg {
		img {
			width: 100%;
		}
	}
	.img-double {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 50%;
		}
	}
	.gif-padding {
		padding: 9.25% 17.5%;
	}
	.img-mobile {
		background-color: black;
		padding: 10% 15%;
	}
	.img-triple {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		img {
			width: 100%;
		}
		&-1 {
			grid-column: 1/2;
			grid-row: 1/3;
		}
		&-2 {
			grid-column: 2/3;
			grid-row: 1/2;
		}
		&-3 {
			grid-column: 2/3;
			grid-row: 2/2;
		}
	}
}
