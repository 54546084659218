.data-list-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.data-list-wrapper {
		width: 100%;
		// overflow: auto;
		.list {
			width: 100%;
			display: grid;
			// overflow: auto;
			grid-template-columns: 1fr 1fr 1fr;
			flex-wrap: wrap;
			justify-content: space-between;
			&::-webkit-scrollbar {
				display: none;
			}
			// .fade-in{
			// 	animation: fadein 3s;
			// 	-moz-animation: fadein 3s; /* Firefox */
			// 	-webkit-animation: fadein 3s; /* Safari and Chrome */
			// 	-o-animation: fadein 3s; /* Opera */
			// }
			.item-container {
				width: 100%;
				float: left;
				.item-wrapper {
					cursor: pointer;
					text-decoration: none;
					color: inherit;
					width: 100%;
					height: 100%;
					position: relative;
					&-content {
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0.9;
						background-color: #00693e;
						width: 100%;
						height: 100%;
						transition: all 0.3s;
						text-align: center;

						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						font-size: 24px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						font-family: "GothamRoundedMedium";
						line-height: 1;
						letter-spacing: normal;
						padding: 0 20px;
						display: none;
						p {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						@media screen and (max-width: 1280px) {
							font-size: 20px;
						}
						@media screen and (max-width: 767px) {
							font-size: 16px;
						}
					}
					// &:hover {
					// 	&-content {
					// 		display: flex;
					// 	}
					// }
					.img-box {
						display: flex;
						position: relative;
						width: 100%;
						height: 100%;
						.contents_img {
							width: 100%;
							height: 100%;
							background-color: black;
							background-size: cover;
							background-position: center;
						}
						img {
							-webkit-user-drag: none;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-use-select: none;
							user-select: none;
							width: 100%;
							height: auto;
						}
					}

					.img-box:after {
						content: "";
						position: absolute;
						// background-color: #333;
						opacity: 0;
						width: 100%;
						height: 100%;
						transition: all 0.3s;
					}
					.top-text-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 25px 0;
						.title {
							font-weight: 500;
							font-size: 18px;
							font-family: "GothamRoundedMedium";
							line-height: 1;
							letter-spacing: normal;
							// color: #444;
							display: inline-block;
							position: relative;
						}
						.title:after {
							content: "";
							position: absolute;
							left: 0;
							bottom: 50%;
							width: 0;
							height: 2px;
							background-color: #333;
							transition: all 0.3s;
						}
						.date {
							font-family: "GothamRoundedMedium";
							font-weight: 300;
							font-size: 12px;
							line-height: 1;
							letter-spacing: normal;
							// color: #999;
							// border-bottom: 1px solid #999;
						}
					}
				}
				.bottom-text-box {
					.address {
						font-weight: 300;
						font-size: 13px;
						font-family: "GothamRoundedMedium";
						line-height: 1.5;
						letter-spacing: normal;
						text-align: left;
						// color: #999;
					}
				}
			}
			.item-container:hover {
				.img-box:after {
					background-color: #333;
					opacity: 0.3;
				}
				.item-wrapper-content {
					display: block;
				}
				.top-text-box {
					.title:after {
						width: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1399px) {
		.data-list-wrapper {
			.list {
				.item-container {
					.item-wrapper {
						&:hover::after {
							font-size: 12px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 767.98px) {
		.data-list-wrapper {
			.list {
				grid-template-columns: 1fr;
			}
		}
	}
	// @media screen and (max-width: 374.98px) {
	// 	.data-list-wrapper {
	// 		.list {
	// 			grid-template-columns: 1fr;
	// 		}
	// 	}
	// }
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@-moz-keyframes fadein { /* Firefox */
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@-webkit-keyframes fadein { /* Safari and Chrome */
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@-o-keyframes fadein { /* Opera */
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}

