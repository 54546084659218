.work-container {
	width: 100%;
	min-width: 1400px;
	max-width: 3200px;
	.work-wrapper {
		width: 100%;
		margin-top: 100px;
		.work-menu-wrapper {
			width: 100%;
			margin-top: 80px;
		}
	}
	@media screen and (max-width: 1399px) {
		min-width: unset;
	}
}
