.home-container {
	width: 100%;
	min-width: 1400px;
	max-width: 3200px;

	.group {
		width: 100%;

		.intro-character {
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			background: white;

			.img {
				display: block;
				width: 49%;
				height: 100%;
				z-index: 2;
			}

			.up-background {
				display: flex;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				z-index: 0;

				.left-back {
					display: flex;
					width: 100%;
					height: 50%;
					background-color: #00693e;
				}

				.right-back {
					display: flex;
					width: 100%;
					height: 50%;
					background-color: #eeeeee;
				}
			}

			.down-background {
				display: flex;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 50%;

				.left-back {
					display: flex;
					width: 100%;
					height: 50%;
					background-color: #fff;
				}

				.right-back {
					display: flex;
					width: 100%;
					height: 50%;
					background-color: #00693e;
				}
			}

			.area {
				z-index: 4;
				display: flex;
				position: absolute;
				top: 0;
				left: 25.5%;

				height: 100%;
				width: 49%;

				.scroll-btn {
					cursor: pointer;
					display: flex;

					width: 100%;
					height: 50%;
					// border: 1px solid red; //영역 표시용
				}
			}

			.up-area {
				top: 0;
			}

			.down-area {
				top: 50%;
			}
		}
	}

	.intro-text {
		font-family: "GothamRoundedBook";
		width: 100%;
		font-size: 24px;
		padding-top: 30px;
		padding-left: 25%;
		padding-right: 32px;


		.text-bold {
			font-weight: 600;
			font-size: 24px;
			font-family: "GothamRoundedBold";
		}
	}

	.ko {
		font-family: "Noto Sans KR", sans-serif;
		font-size: 22px;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0px;
	}

	.en {
		letter-spacing: .1px;
	}

	.intro-video {
		width: 100%;

		padding-top: 120px;
		padding-left: 32px;
		padding-right: 32px;

		.video {
			width: 100%;
		}
	}

	.talent-recruitment {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.title {
			font-family: "GothamRoundedMedium";
			font-size: 36px;
			margin-top: 100px;
			margin-bottom: 46px;
		}

		.sub-text {
			font-family: "GothamRoundedBook";
			font-size: 24px;
			margin-bottom: 16px;
		}

		.end-text {
			margin-bottom: 0px;
		}

		.ko {
			font-family: "Noto Sans KR", sans-serif;
			font-size: 18px;
			font-weight: 600;
			line-height: 1.74;
			letter-spacing: 0px;
		}

		.en {
			font-family: "GothamRoundedBook";
			font-size: 24px;
		}


		.under-line {
			text-decoration: underline;
			text-underline-position: under;
		}

		.btn-list {
			display: flex;
			margin-top: 46px;
			gap: 12px;

			span {
				font-family: "GothamRoundedBook";
				font-size: 18px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 15px;
				background-color: black;
				color: white;
			}

			.sand {
				width: 98px;
				height: 30px;
			}

			.download {
				width: 144px;
				height: 30px;
			}
		}
	}

	@media screen and (max-width: 1400px) {
		min-width: 1px;

		.intro-text {
			font-size: 20px;

			.text-bold {
				font-size: 20px;
			}
		}

		.player-container {
			padding-top: 100px;
		}
	}

	@media screen and (max-width: 1200px) {
		min-width: 1px;

		.intro-text {
			font-size: 16px;

			.text-bold {
				font-size: 16px;
			}
		}

		.player-container {
			padding-top: 80px;
		}
	}

	@media screen and (max-width: 1050px) {
		.intro-text {
			font-size: 12px;

			.text-bold {
				font-size: 12px;
			}
		}

		.player-container {
			padding-top: 60px;
		}
	}

	@media screen and (max-width: 767px) {
		padding-top: 80px;

		.intro-text {
			font-size: 22px;
			padding-top: 30px;
			padding-left: 15px;
			padding-right: 15px;

			.text-bold {
				font-weight: 600;
				font-size: 22px;
				font-family: "GothamRoundedBold";
			}

		}

		.player-container {
			padding-top: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.home-distance {
			height: 30px !important;
		}
	}

	@media screen and (max-width: 680px) {
		.intro-text {
			font-size: 14px;

			.text-bold {
				font-size: 14px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.home-container {
		.group {
			.intro-character {
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				background: white;

				.img {
					display: block;
					width: 100%;
					height: 100%;
				}

				.area {
					display: flex;
					position: absolute;
					top: 0;
					left: 0;

					height: 100%;
					width: 100%;

					.scroll-btn {
						cursor: pointer;
						display: flex;

						width: 100%;
						height: 50%;
						// border: 1px solid red; //영역 표시용
					}
				}

				.up-area {
					top: 0;
				}

				.down-area {
					top: 50%;
				}
			}
		}
	}
}