.intro-space-container {
	width: 100%;
	padding-top: 130px;
	padding-left: 32px;
	padding-right: 32px;

	.grid-wrapper {
		display: grid;
		width: 100%;
		grid-gap: 15px;
		grid-template-columns: repeat(3, 1fr);
		&.left {
			> :first-child {
				grid-column: 1 / 3;
				grid-row: 1 / 2;
			}
		}
		&.right {
			> :nth-child(2) {
				grid-column: 2 / 4;
				grid-row: 1 / 2;
			}
		}

		div {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.worker {
			display: flex;
			.box {
				.image-button {
					position: relative;
					width: 100%;
					height: 100%;

					.more {
						font-family: "GothamRoundedBook";
						font-size: 18px;
						cursor: pointer;
						margin-top: 60%;
						margin-left: 5%;
						position: absolute;
						top: 0;
						left: 0;
						background-color: #000;
						width: 85px;
						height: 30px;
						border-radius: 15px;
						z-index: 15;
						p {
							display: flex;
							vertical-align: middle;
							justify-content: center;
							align-items: center;
							line-height: 30px;
							color: #fff;
						}
					}
					.index-zero {
						background-color: #fff;
						p {
							color: #00693e;
						}
					}
				}
			}
		}
		.work {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			overflow: hidden;
			.title {
				font-family: "GothamRoundedBold";
				font-size: 24px;
				margin-top: 16px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
				height: 36px;
			}
			.text {
				font-family: "GothamRoundedBook";
				font-size: 18px;
				margin-top: -2px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
				height: 36px;
				color: #000;
			}

			.box {
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: relative;

				.image-button {
					cursor: pointer;
					width: 100%;
					height: 100%;
					.image-src {
						width: 100%;
						height: 100%;
					}

					.workTitle {
						font-family: "GothamRoundedMedium";
						font-size: 24px;
						position: absolute;
						top: 90%;
						@media only screen and (max-width: 1700px) {
							top: 88%;
						}
						@media only screen and (max-width: 1500px) {
							top: 85%;
						}

						left: 3%;
						width: 100%;
						height: 100%;
						color: #fff;
						z-index: 10;
					}
					.workDiscription {
						position: absolute;
						margin-top: 30px;
						top: 90%;
						@media only screen and (max-width: 1700px) {
							top: 88%;
						}
						@media only screen and (max-width: 1500px) {
							top: 85%;
						}
						left: 3%;
						width: 91%;
						height: 36px;
						z-index: 10;
						p {
							font-family: "GothamRoundedBook";
							font-size: 18px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							width: 100%;
							height: 36px;
							color: #fff;
						}
					}
					.background {
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: rgba(44, 103, 66, 0.9);
						top: 0;
						left: 0;
						opacity: 0;
						transition: 0.3s;
						p {
							font-size: 24px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							font-family: "GothamRoundedMedium";
							line-height: 1;
							display: flex;
							vertical-align: middle;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 100%;
							color: #fff;
						}
					}
				}
				&:hover {
					.background {
						opacity: 1;
					}
					.workTitle {
						z-index: 99;
						p {
							color: #fff !important;
						}
					}
					.workDiscription {
						z-index: 99;
						p {
							color: #fff !important;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
	.grid-wrapper  {
		display: flex;
		flex-direction: column;
		grid-gap: 15px;
		.worker {
			display: flex;
			order: -1;
		}
	}
	.grid-wrapper .work .box .image-button .workTitle {
		position: relative;
		margin-top: 5px;
		font-size: 20px;
		p {
			color: #000;
		}
		// top: 63%
	}
	 .grid-wrapper .work .box .image-button .workDiscription {
		position: relative;
		margin-top: 10px;
		p {
			font-size: 14px;
			color: #000;
		}
	}
	.grid-wrapper .worker .box .image-button .more {
		font-size: 14px;
		top: 0;
		left: 0;
		background-color: #000;
		margin-top: 98%;
		height: 23px;
		border-radius: 15px;
		p {
			line-height: 1.6;
		}
	}
	.grid-wrapper .work .title {
		font-size: 20px;
		height: 30px;
		margin-top: 8px;
	}
	.grid-wrapper .work .text {
		font-size: 13px;

	}
	.grid-wrapper .work .box .image-button .background  {
		display: none;
	}
	.grid-wrapper .work .box .image-button .background p {
		font-size: 12px;
	}
	 .grid-wrapper .work .box:hover .workTitle p {
		color: #000 !important;
	}
	 .grid-wrapper .work .box:hover .workDiscription p {
		color: #000 !important;
	}
	}

}
