.home-laneige {
	--fsTit: 80px;
	--fsDetail: 30px;
	--fsTitOverview: 49px;
	--fsDetailOverView: 18px;
	--maxHeight: 1163px;
	--widthOverView: 1500px;
	--paddingOverview: 150px 25px;
	--pbContentOverview: 115px;
	color: #fff;

	.imgBg {
		img {
			width: 100%;
		}
	}
	.img-double {
		display: flex;
		flex-direction: column;
		padding: 10.416666666% 0 13.020833333% 0;

		&-1 {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			margin-bottom: 6.26174076%;
			img {
				padding-left: 17.45%;
				padding-right: 34.11%;
				width: 100%;
			}
		}
		&-2 {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			img {
				width: 100%;
				padding-right: 17.447916666%;
				padding-left: 34.1145833%;
			}
		}
	}
	.gif-padding {
		padding: 9.25% 17.5%;
	}
	.img-mobile {
		background-color: black;
		padding: 10% 15%;
	}
}
