.sulwhasoo {
    font-family: NotoSansCJKkr;
    background: #000000;
    color: #fff;

    img,
    video,
    iframe {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .title {
        font-size: 24px;
    }

    .detail {
        font-size: 18px;
        line-height: 1.8;
    }

    &-overview {
        background: #000000;

        &-container {
            max-width: 1400px;
            padding: 120px 25px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 18% auto;
            color: #fff;

            .title {
                font-size: 24px;
                font-family: Montserrat-Bold;
                font-weight: bold;
            }

            .detail {
                font-size: 18px;
                line-height: 1.8;
            }

            @media screen and (max-width: 1080px) {
                max-width: 1200px;
                padding: 50px 25px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                color: #fff;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 18px;
                    line-height: 1.8;
                    padding-top: 30px;
                    max-width: 80%;

                    br {
                        display: none;
                    }
                }
            }
        }
    }

    &-secret {
        position: relative;

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;

            .title {
                font-size: 56px;
                line-height: 1.4;

                @media screen and (max-width: 1080px) {
                    font-size: 36px;

                    br {
                        display: none;
                    }
                }

                @media screen and (max-width: 767px) {
                    font-size: 18px;

                }
            }

            .detail {
                font-size: 18px;
                line-height: 1.8;
                padding-top: 47px;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    padding-top: 17px;

                    br {
                        display: none;
                    }
                }
            }
        }

        &-container {
            .img {
                @media screen and (max-width: 767px) {
                    height: 600px;

                    img {
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &-video {
        height: 100vh;
    }

    &-concept {
        position: relative;

        .content {
            max-width: 1250px;
            top: 120px;
            margin: 0 auto;
            display: grid;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 333;
            grid-template-columns: 25% auto;
            padding: 0 25px;

            .title {
                font-size: 24px;
            }

            .detail {
                font-size: 18px;
                line-height: 1.7;
            }

            @media screen and (max-width: 1080px) {
                padding: 50px 25px;
                display: flex;
                flex-direction: column;
                color: #fff;
                top: 20px;
                width: 100%;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 18px;
                    line-height: 1.8;
                    padding-top: 30px;
                    max-width: 80%;

                    br {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                padding: 20px;
                top: 0;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 14px;
                    line-height: 1.8;
                    padding-top: 15px;
                    max-width: 90%;

                    br {
                        display: none;
                    }
                }

            }
        }

        &-img {
            @media screen and (max-width: 767px) {
                height: 600px;
            }
        }

        .list-img {
            position: relative;
            max-width: 1600px;
            margin: 0 auto;
            padding: 50px 25px 200px;
            height: 100%;
            z-index: 3;

            .img-item {
                position: absolute;
                max-width: 405px;
                max-height: 308px;
                object-fit: contain;
            }

        }
    }

    &-key {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &-gif {
        &-container {
            max-width: 1350px;
            padding: 120px 25px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 18% auto;
            color: #fff;

            .title {
                font-size: 24px;
            }

            .detail {
                font-size: 18px;
                line-height: 1.8;
            }

            @media screen and (max-width: 1080px) {
                padding: 50px 25px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                color: #fff;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 18px;
                    line-height: 1.8;
                    padding-top: 30px;
                    max-width: 80%;

                    br {
                        display: none;
                    }
                }
            }
        }

    }

    &-logo {
        background-color: #252422;

        &-container {
            max-width: 1350px;
            padding: 120px 25px;
            margin: 0 auto;
        }

    }

    &-keyvisual {
        &-container {
            max-width: 1350px;
            padding: 120px 25px;
            margin: 0 auto;

            .content {
                display: grid;
                grid-template-columns: 15% auto;
                padding-bottom: 300px;

                @media screen and (max-width: 1080px) {
                    display: flex;
                    flex-direction: column;

                    .detail {
                        padding-top: 30px;
                        max-width: 80%;

                        br {
                            display: none;
                        }
                    }

                    padding-bottom: 100px;
                }

                @media screen and (max-width: 1080px) {
                    display: flex;
                    padding-bottom: 50px;
                }
            }
        }
    }

    &-list-gif {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}