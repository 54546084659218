.localMoney {
    --maxHeight: 1224px;
    --paddingContent: 195px 0 100px;
    --maxWidthContent: 1200px;
    --fsDetailIntro: 26.5px;
    --ptDetailIntro: 40px;
    --mtImgIntro: 200px;
    --maxHeightImgIntro: 523px;
    --paddingIllu: 149px 25px;
    --gapType: 100px;
    --mtCharacter: 148px;
    --gapCharacter: 150px;
    font-family: notoSans;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &-bg {
        max-height: var(--maxHeight);
    }

    &-intro {
        padding: var(--paddingContent);

        .content {
            max-width: var(--maxWidthContent);
            padding: 0 25px;
            margin: 0 auto;

            .subtit {
                color: #0b4a89;
                font-size: 23.5px;
            }

            .detail {
                font-size: var(--fsDetailIntro);
                line-height: 1.5;
                padding-top: var(--ptDetailIntro);
            }
        }

        .img {
            margin-top: var(--mtImgIntro);
            height: var(--maxHeightImgIntro);
        }
    }

    &-target_ {
        padding: var(--paddingContent);
        max-width: var(--maxWidthContent);
        padding-left: 25px;
        padding-right: 25px;
        margin: 0 auto;

        .content {

            .subtit {
                color: #0b4a89;
                font-size: 23.5px;
            }

            .detail {
                font-size: var(--fsDetailIntro);
                line-height: 1.5;
                padding-top: var(--ptDetailIntro);
            }
        }

        .img {
            margin-top: var(--mtImgIntro);
            height: var(--maxHeightImgIntro);
        }
    }

    &-concept {
        max-width: var(--maxWidthContent);
        padding: 0 25px;
        margin: 0 auto;
    }

    &-illu {
        background-color: #282828;
        color: #fff;

        &-container {
            max-width: var(--maxWidthContent);
            padding: var(--paddingIllu);
            margin: 0 auto;

            .title {
                font-size: 23.5px;
            }

            .detail {
                font-size: var(--fsDetailIntro);
                padding-top: var(--ptDetailIntro);
                line-height: 1.5;
                white-space: pre-line;
            }
        }
    }

    &-type {
        background-color: #fafafa;
        color: #282828;

        &-container {
            max-width: var(--maxWidthContent);
            padding: var(--paddingIllu);
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: var(--gapType);

            .title {
                font-size: 23.5px;
                font-weight: bold;
            }

        }
    }

    &-character {
        padding: var(--paddingContent);

        &-container {
            max-width: var(--maxWidthContent);
            padding: 0 25px;
            margin: 0 auto;

            .content {

                .subtit {
                    font-size: 23.5px;
                }

                .detail {
                    font-size: var(--fsDetailIntro);
                    line-height: 1.5;
                    padding-top: var(--ptDetailIntro);
                }
            }

            .img {
                margin-top: var(--mtCharacter);
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
                row-gap: var(--gapCharacter);

            }
        }

    }

    &-key {
        position: relative;
        padding-bottom: 150px;

        // height: 3500px;
        &-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            img {
                object-fit: cover;
            }
        }

        &-container {
            max-width: var(--maxWidthContent);

            padding: var(--paddingContent);
            padding-left: 25px;
            padding-right: 25px;
            margin: 0 auto;

            .content {

                .subtit {
                    font-size: 13.5px;
                    color: #0b4a89;
                }

                .detail {
                    font-size: var(--fsDetailIntro);
                    line-height: 1.5;
                    padding-top: var(--ptDetailIntro);
                }
            }

            .img {

                .img_item {
                    // width: 900px;
                    padding-top: 150px;
                    display: flex;
                }

                .img_2,
                .img_4 {
                    justify-content: right;

                }

                img {
                    max-width: 700px;
                }
            }
        }

    }

    @media screen and (max-width: 1080px) {
        --maxHeight: 1224px;
        --paddingContent: 105px 0 60px;
        --maxWidthContent: 1200px;
        --fsDetailIntro: 20.5px;
        --ptDetailIntro: 30px;
        --mtImgIntro: 130px;
        --maxHeightImgIntro: 423px;
        --paddingIllu: 79px 25px;
        --gapType: 50px;
        --mtCharacter: 68px;
        --gapCharacter: 70px;
    }

    @media screen and (max-width: 767px) {
        --maxHeight: 100vh;
        --paddingContent: 55px 0 30px;
        --fsDetailIntro: 16.5px;
        --ptDetailIntro: 20px;
        --mtImgIntro: 70px;
        --maxHeightImgIntro: 323px;
        --paddingIllu: 39px 25px;
        --gapType: 40px;
        --mtCharacter: 38px;
        --gapCharacter: 40px;
    }
}