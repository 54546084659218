.artificialPage-bg {
	--fsTit: 134px;
	--fsDetail: 30px;
	--fsTitOverview: 49px;
	--fsDetailOverView: 19px;
	--maxHeight: 1349px;
	--widthOverView: 1500px;
	--paddingOverview: 150px 25px;
	--pbContentOverview: 115px;
	--blackColor: #171717;

	color: #fff;
	background-color: #171717;


	.top,
	.title {
		font-family: AveriaSerif, serif;
	}
	.bottom,
	.detail {
		font-family: NotoSerifCJKkr, serif;
	}
	.background {
		max-height: var(--maxHeight);
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;

			.title {
				font-size: var(--fsTit);
			}

			.detail {
				font-size: var(--fsDetail);
				line-height: 2;
			}
		}
	}
	.totop {
		transform: translateY(-1px);
	}
	img {
		display: block;
	}
	.section {
		background-image: url("../../Source/bg-planning.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&-container {
			padding-top: 5em;
			padding-bottom:3em;
			top: 0;
			width: 100%;
			font-size: 1rem;

			.section-content {
				width: 100%;
				padding-left: 15em;
				display: flex;
				gap: 9em;
				color: #000000;
				font-size: 1em;

				.title {
					font-size: 3em;
					font-weight: bold;
				}

				.detail {
					font-size: 1.125em;
					line-height: 1.8;
					max-width: 446px;
					word-break: normal;
					width: 100%;
				}
			}

			.section-img {
				position: relative;
				margin-top: 8em;
				width: 100%;
				font-size: 1em;

				.item {
					font-size: 1em;
				}
				.item:nth-child(1) {
					width: 100%;
					img {
						margin-left: 18em;
						width: 40%;
						max-width: 770px;
					}
				}
				.item:nth-child(2) {
					width: 100%;

					display: flex;
					justify-content: flex-end;
					img {
						margin-right: 8em;
						width: 31%;
						max-width: 610px;
					}
				}
				.item:nth-child(3) {
					width: 100%;

					display: flex;
					justify-content: center;

					img {
						margin-right: 8em;
						width: 23%;
						max-width: 450px;
					}
				}
			}

			@media screen and (max-width: 1400px) {
				font-size: 14px;
			}
			@media screen and (max-width: 1024px) {
				font-size: 12px;
				.section-content{
					font-size:16px;
					padding-left: 5em;
					flex-direction: column;
					gap: 5em;
				}
			}
			@media screen and (max-width: 500px) {
				.section-content{
					flex-direction: column;
					padding-left: 2em; 
					padding-right: 2em; 
					gap: 3em;
					font-size: 18px;
				}
				.section-img{
					font-size:  5px;

					.item{
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.item:nth-child(1) {
						img{
							margin: unset;
							width: 90%;
						}

					}
					.item:nth-child(2) {
						justify-content: center;
						img{
							margin: unset;
							width: 90%;
						}


					}
					.item:nth-child(3) {

						img{
							margin: unset;
							width: 90%;
						}

					}
				}
			}
		}
	}
	.keyword {
		background-image: url("../../Source/bg-keywords.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&-container {
			padding-top: 5em;
			padding-bottom: 40em;
			top: 0;
			width: 100%;
			font-size: 1rem;

			.section-content {
				width: 100%;
				padding-right: 15em;
				display: flex;
				gap: 9em;
				color: #000000;
				flex-direction: row-reverse;
				font-size: 1em;

				.title {
					font-size: 3em;
					font-weight: bold;
				}

				.detail {
					font-size: 1.125em;
					line-height: 1.8;
					max-width: 446px;
					word-break: normal;
					width: 100%;
					flex-grow: 2;
				}
			}

			.section-img {
				position: relative;
				margin-top: 10em;
				width: 100%;
				font-size: 1em;

				.item {
					font-size: 1em;
				}
				.item:nth-child(1) {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 80%;
						max-width: 1252px;
					}
				}
			
			}

			@media screen and (max-width: 1400px) {
				font-size: 14px;
			}
			@media screen and (max-width: 1024px) {
				font-size: 12px;
				.section-content{
					font-size:16px;
					padding-left: 5em;
					flex-direction: column;
					gap: 5em;
					padding-right: 5em;
				}
			}
			@media screen and (max-width: 500px) {
				.section-content{
					flex-direction: column;
					padding-left: 2em; 
					padding-right: 2em; 
					gap: 3em;
					font-size: 18px;
				}
				.section-img{
					font-size:  5px;

					.item{
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.item:nth-child(1) {
						img{
							margin: unset;
							width: 90%;
						}

					}
					.item:nth-child(2) {
						justify-content: center;
						img{
							margin: unset;
							width: 90%;
						}


					}
					.item:nth-child(3) {

						img{
							margin: unset;
							width: 90%;
						}

					}
				}
			}
		}
	}

	.gif {
		// transform: translateY(-30px);
		width: 100%;
		img {
			width: 100%;
		}
	}
	.margintop-50 {
		margin-top: 50px;
	}
	.direction {
		.section-content{
			color:#fff;

			.title{
				font-size: 49px;
			}
			.smaller{
				font-size: 28px;
			}
		}
		.section-container{
			.section-img{
				img{
					width: 100%;
					object-fit: cover;
				}
			}
		}
	
		@media screen and (max-width: 1440px) {
			.section-content {
				padding-left: 100px;
			}
		}
		@media screen and (max-width: 1440px) {
			.section-content {
				padding-left: 70px;
				padding-right: 70px;
				.title {
					font-size: 28px;
					word-break: keep-all;
					text-align: left;
				}
				.detail {
					font-size: 20px;
					word-break: keep-all;
				}
			}
		}
		@media screen and (max-width: 768px) {
			.section-container {
				padding: 50px 0;
			}
			.section-img {
				margin-top: 70px;
			}
			.section-content {
				padding-left: 50px;
				padding-right: 50px;
				.title {
					font-size: 22px;
					word-break: keep-all;
					text-align: left;
				}
				.detail {
					font-size: 16px;
					word-break: keep-all;
				}
			}
		}
		@media screen and (max-width: 500px) {
			.section-container {
				padding: 50px 0;
			}
			.section-img {
				margin-top: 70px;
			}
			.section-content {
				padding-left: 50px;
				padding-right: 50px;
				flex-direction: column;
				gap: 20px;
				.title {
					font-size: 22px;
					word-break: keep-all;
					text-align: left;
				}
				.detail {
					font-size: 16px;
					word-break: keep-all;
					width: 100%;
				}
			}
		}
	}

	.refrence {
		// transform: translateY(-45px);
		padding: 60px  0 115px;
		background-color: var(--blackColor);

		.title {
			font-size: 49px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.23;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
			word-break: keep-all;
		}
		.main-img {
			margin-top: 30px;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.bird {
			margin-top: 115px;
			display: grid;
			grid-template-columns: 50% 50%;

			.item {
				height: auto;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			@media screen and (max-width: 1024) {
				margin-top: 100px;
			}
			@media screen and (max-width: 768px) {
				margin-top: 50px;
			}
			@media screen and (max-width: 500px) {
				margin-top: 30px;
			}
			
		}
	}

	.refrence-bottom {
		background-image: url("../../Source/bg-referencebg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		font-size: 49px;
		position: relative;

		padding: 8em 0px 12em;

		.title {
			display: flex;
			gap: 32px;
			flex-direction: column;
			font-size: 1em;
			padding-left: 5em;
			padding-bottom: 4em;
			.top {
				font-size: 49px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.23;
				letter-spacing: normal;
				text-align: left;
				color: #fff;
			}

			.bottom {
				width: 381px;
				mix-blend-mode: luminosity;
				font-size: 19px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.79;
				letter-spacing: normal;
				text-align: left;
				color: #fff;
			}
		}

		.item-container {
			display: flex;
			flex-direction: column;
			font-size: 49px;
			gap: 4em;
			.item {
				display: flex;
				width: 80%;
				margin: 0 auto;
				gap: 2em;
				&.from-right {
					flex-direction: row-reverse;

					.content {
						text-align: right;
					}
				}

				.item-img {
					&.img-2 {
						width: 95%;
					}
					&.img-3 {
						width: 95%;
					}

					img {
						width: 100%;
						height: auto;
						object-fit: cover;
					}

					&:nth-child(1) {
						max-width: 1100px;
					}
				}

				.content {
					display: flex;
					flex-direction: column;
					gap: 22px;

					.top {
						font-size: 22.5px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.18;
						letter-spacing: normal;
						text-align: left;
						color: #fff;
					}
					.bottom {
						font-size: 19px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.79;
						letter-spacing: normal;
						text-align: left;
						color: #fff;
						max-width: 300px;
					}

					&.right {
						.top {
							text-align: right;
						}
						.bottom {
							text-align: right;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1024px) {
			font-size: 30px;

			.top {
				font-size: 39px !important;
				word-break: keep-all;
			}
		}
		@media screen and (max-width: 768px) {
			font-size: 20px;

			.item {
				flex-direction: column;

				&.from-right {
					.bottom {
						text-align: left !important;
					}
					flex-direction: column !important;
				}
				.content {
					flex-direction: row !important;
				}
			}
			.top {
				font-size: 30px !important;
				word-break: keep-all;
			}
		}
		@media screen and (max-width: 500px) {
			font-size: 12px;
			padding-right: 1em;
			.title {
				padding-right: 1em;
				.bottom {
					width: unset;
				}
			}
			.item {
				gap: 0.5em !important;
				.top {
					font-size: 30px !important;
					word-break: keep-all;
				}
				.bottom {
					font-size: 16px !important;
					width: unset !important;
				}
			}
		}
	}

	.conti {
		position: relative;
		background-image: url("../../Source/bg-conti.png");

		background-repeat: no-repeat;
		background-size: cover;
		font-size: 49px;
		padding: 10em 1em 3em;

		.content {
			position: absolute;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			top: 1em;

			left: 50%;

			transform: translateX(-50%);
			.top {
				font-size: 49px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.61;
				letter-spacing: normal;
				color: #fff;
			}
			.bottom {
				font-size: 19px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.79;
				letter-spacing: normal;
				text-align: center;
				color: #fff;
				max-width: 600px;
			}
		}

		.img-main {
			display: block;
			width: 100%;
		}

		@media screen and (max-width: 1024px) {
			font-size: 40px;

			.content {
				.top {
					font-size: 30px;
					top: 0.5em;
				}
				.bottom {
					font-size: 16px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			font-size: 30px;

			.content {
				.top {
					font-size: 30px;
					top: 0.5em;
				}
				.bottom {
					font-size: 16px;
				}
			}
		}
		@media screen and (max-width: 500px) {
			font-size: 25px;

			.content {
				gap: 10px;
				.top {
					font-size: 25px;
					top: 0.5em;
				}
				.bottom {
					font-size: 12px;
				}
			}
		}
	}

	.conti-bottom {
	
		display: grid;
		grid-template-columns: 50% 50%;
		// transform: translateY(-50px);
		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}

	.key-visual {
		background-image: url("../../Source/bg-keyvisual.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;	
		
		font-size: 1rem;
		padding-top: 9em;
		padding-bottom: 20em;
		display: flex;
		flex-direction: column;
		gap: 4em;

		.content {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: 32px;
			justify-content: center;

			.top {
				font-size: 49px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.61;
				letter-spacing: normal;
				text-align: center;
				color: #fff;
			}
			.bottom {
				font-size: 19px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.79;
				letter-spacing: normal;
				text-align: center;
				color: #fff;
				max-width: 570px;
			}
		}

		.img-container {
		
			width: 100%;

			img {
				display: block;
			}

			.item {
				display: flex;
				&.right {
					justify-content: flex-end;
				}
			}

			.item:nth-child(1) {
				justify-content: center;
				width: 100%;
				img {
					width: 80%;
				}
			}
			.item:nth-child(2) {
				padding-right: 100px;
				img {
					width: 50%;
				}
			}
			.item:nth-child(3) {
				justify-content: flex-start;
				img {
				margin-left: 200px;
					width: 60%;
				}
			}
			.item:nth-child(4) {
				justify-content: center;
				img {
					width: 80%;
				}
			}
		}


		@media screen and (max-width: 1024px) {
			font-size: 14px;
		}
		@media screen and (max-width: 768px) {
			font-size: 14px;

			.content{
				.bottom{
					font-size: 14px;
					width: 90%;
				}
			}
			.img-container .item.right{
				justify-content:flex-start;
				padding: unset;
			}

			.img-container .item {
				width: 100%;
				display: flex;
				justify-content: center  !important;
				align-items: center !important;
				img{
					width: 80% !important;
					padding: unset !important;
					margin: unset  !important;
					margin:unset  !important;
				}
			}

		}
	}
	.opening-img {
		width: 100%;
		// transform: translateY(-61px);
		img {
			display: block;
			width: 100%;
			object-fit: cover;
		}
	}
	.epi-img {
		width: 100%;
		// transform: translateY(-62px);
		img {
			display: block;
			width: 100%;
			object-fit: cover;
		}
	}
}
