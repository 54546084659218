.player-container {
	width: 100%;
	padding-top: 130px;
	padding-left: 32px;
	padding-right: 32px;
	.player-wrapper {
		width: 100%;
		height: 100%;
		position: relative;
		padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
		overflow: hidden;

		.player-button {
			position: absolute;
			display: flex;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			align-items: center;
			display: flex;
			justify-content: center;

			.content {
				position: absolute;
				top: 0;
				left: 0;
			}
			.thumbnail {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 14;
			}
			.btn {
				cursor: pointer;
				z-index: 15;
				opacity: 0;
				transition: 0.3s;
			}
			&:hover {
				.btn {
					opacity: 1;
				}
			}
		}
	}
}
