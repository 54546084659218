.lottegroup {
	img {
		display: block;
	}

	.main-bg {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.over-view {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 140px;
		padding-bottom: 140px;
		background-color: #fff;
		.content {
			width: 65%;
			display: flex;
			gap: 27px;
			flex-direction: column;

			.top {
				font-size: 30px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.2;
				letter-spacing: normal;
				text-align: left;
				color: #3b160c;
				font-family: GmarketSans, AppleGothic, sans-serif;

				word-break: keep-all;
			}
			.bottom {
				font-family: Pretendard;
				font-size: 20px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: normal;
				text-align: left;
				color: #3b160c;
				word-break: keep-all;
			}
		}

		@media screen and (max-width: 768px) {
			padding-top: 100px;
			padding-bottom: 100px;
			.content {
				width: 80%;
				.bot,
				.bottom {
					word-break: keep-all;
				}
			}
		}
	}

	.concept {
		background-color: #fff;
		padding-top: 140px;
		padding-bottom: 150px;
		display: flex;
		justify-content: center;
		flex-direction: column;

		.title {
			font-family: GmarketSans;
			font-size: 30px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 4;
			letter-spacing: normal;
			text-align: center;
			color: #3b160c;
		}

		.core-img {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 100px;
			justify-content: center;
			align-items: center;
			padding: 0 100px 0;

			.item {
				width: 100%;
				display: flex;
				gap: 48px;
				


				&.right {
					flex-direction: row-reverse;
				}

				img {
					max-width: 1190px;
					max-height: 670px;
					width: calc(70%);
					object-fit: cover;
				}
				.text {
					width: 30%;
					display: flex;
					gap: 20px;

					@media screen and (max-width: 1540px) {
						flex-direction: column;
					}

					.top {
						font-family: Pretendard;
						font-size: 30px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.2;
						letter-spacing: normal;
						text-align: left;
						color: #3b160c;

						word-break: break-word;

						@media screen and (max-width: 1440px) {
							font-size: 20px;
						}
					}
					.bottom {
						font-family: Pretendard;
						font-size: 24px;
						font-weight: 300;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.25;
						letter-spacing: normal;
						text-align: left;
						color: #3b160c;
						word-break: break-word;
						@media screen and (max-width: 1440px) {
							font-size: 16px;
						}
					}

					@media screen and (max-width: 500px) {
						padding: 0 10px 0;
					}
				}

				&:nth-child(2) {
					padding-right: 120px;
					width: 100%;
					max-width: 686px;
					display: flex;

					img {
						width: calc(50%);
					}
					.text {
						width: 50%;
					}
				}
			}
		}

		.bottom-img {
			margin-top: 150px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 80%;
				object-fit: cover;
				max-width: 1351px;
				max-height: 293px;
			}
		}

		@media screen and (max-width: 768px) {
			padding-top: 80px;
			padding-bottom: 120px;

			.core-img {
				padding: 0 20px 0;
				.item {
					flex-direction: column;
					&.right {
						flex-direction: column;
					}
					img {
						width: 100%;
					}

					&:nth-child(2) {
						// padding-right: 120px;
						width: 100%;
						max-width: 686px;
						display: flex;
						padding-right: unset;

						img {
							width: calc(100%);
						}
						.text {
							width: 100%;
						}
					}
					.text {
						width: 100%;

						.top,
						.bottom {
							width: 100%;
							word-break: keep-all;
						}
						.top {
							font-size: 26px;
						}
						.bottom {
							font-size: 18px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 500px) {
			.core-img{
				padding: 0;
			}
		}
	}

	.typography-section {
		background-color: #f2f2f2;
		display: flex;
		flex-direction: column;
		gap: 180px;
		padding: 140px 0 180px;
		.title {
			margin: 0 auto;
			font-family: GmarketSans;
			font-size: 30px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: center;
			color: #3b160c;
			word-break: keep-all;
		}

		.typo-wraper {
			display: flex;
			gap: 300px;
			flex-direction: column;
			width: 100%;

			.item {
				width: 100%;
				display: flex;
				justify-content: center;
				img {
					width: 100%;
					object-fit: cover;
					max-width: 1554px;
				}
			}
		}

		@media screen and (max-width: 1024px) {
			padding: 80px 0 100px;
			gap: 80px;

			.typo-wraper {
				gap: 50px;
			}
		}
	}

	.key-visual-1 {
		background-color: #fff;
		padding: 140px 0px 180px;
		display: flex;
		flex-direction: column;
		gap: 180px;
		.title {
			font-family: GmarketSans;
			font-size: 30px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: center;
			color: #3b160c;
		}

		.key-wraper {
			display: flex;
			gap: 140px;
			flex-direction: column;
			width: 100%;
			justify-content: center;

			.item {
				width: 100%;
				display: flex;
				justify-content: center;
				img {
					object-fit: cover;
				}

				&:nth-child(1) {
					img {
						width: 35%;
						max-width: 428px;
					}
				}
				&:nth-child(2) {
					img {
						width: 60%;
						max-width: 973px;
					}
				}
			}
		}

		@media screen and (max-width: 1024px) {
			padding: 80px 0 100px;
			gap: 80px;

			.key-wraper {
				.item {
					&:nth-child(1) {
						img {
							width: 80%;
							max-width: 428px;
						}
					}
					&:nth-child(2) {
						img {
							width: 80%;
							max-width: 973px;
						}
					}
				}
			}
		}
	}

	.key-visual-2 {
		background-color: #fff;
		padding: 100px 140px 100px;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.item {
			width: 100%;
			img {
				width: 100%;
				object-fit: cover;
				max-width: 1640px;
			}
		}


		@media screen and (max-width: 768px) {
			padding:50px 20px 50px;
		}
	}

	.key-visual-3 {
		background-color: #fff;
		padding: 100px 225px 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 40px;

		.item {
			width: 100%;
			img {
				width: 100%;
				object-fit: cover;
				max-width: 1470px;
			}
		}

		@media screen and (max-width: 768px) {
			padding: 50px 25px 50px;
		}
	}
}
